import React from 'react';
import { useLocation } from 'react-router-dom';

const PageBanner = () => {
  const location = useLocation(); // Gets the current path

  // Define a mapping of routes to page names
  const pageNames = {
    '/about': 'About Us',
    '/services': 'Our Services',
    '/contact': 'Contact Us',
    '/blog': 'Blog',
    '/product': 'Product',
    '/gallery': 'Gallery',
    '/product/deveg': 'Dehydrated Vegetables',
    '/product/drifruit': 'Fruit Powder',
  };

  // Get the page name based on the current path
  const pageTitle = pageNames[location.pathname] || 'Welcome';

  return (
    <section className="page-banner section-padding">
      <div className="container">
        <h1>{pageTitle}</h1>
      </div>
    </section>
  );
};

export default PageBanner;
