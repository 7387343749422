import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Row, Col } from "react-bootstrap";
import locat from '../assets/img/geo-alt.svg';
import call from '../assets/img/telephone.svg';
import email from '../assets/img/envelope.svg';
import fb from '../assets/img/facebook.svg';
import insta from '../assets/img/instagram.svg';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    subject: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then((result) => {
        console.log(result.text);
        setFormStatus('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        setFormStatus('Failed to send message, please try again later.');
      });

    setFormData({
      name: '',
      mobile: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <Container className="contact-form section-padding">
      <Row>
      <Col lg={6} sm={12} className=''>
      <div className='d-flex flex-column infor-di mi-gray bg-white'>
      <h4 className="subtitle">We're Here for You During These Hours.</h4>
      <h2 className="title">Contact Us</h2>
      <ul className="contact-info contact-page">
              <li className='mi-gray'><span>
              <img src={locat} alt="address" className="" />
              </span>Sai Samruddhi Appartment, Jail Road, Nashik 422101, MH - India. </li>
              <li className='mi-gray'><span>
              <img src={call} alt="call" className="" /></span>
              <a href='tel:9359731355'> +91 9359731355</a>/<a href='tel:7798882333'>+91 77988 82333</a></li>
              <li className='mi-gray'><span>
              <img src={email} alt="email" className="" /></span><a href='tel:info@sunriseagriproducts.com'> info@sunriseagriproducts.com </a></li>
        </ul>
        <h2 className="title mt-5">Follow Us</h2>
        <ul className="social-icon contact-social-icon  d-flex">
              <li><a href="#facebook" className="text-light me-3">
              <img src={fb} alt="facebook" className="" /></a></li>
              <li><a href="https://www.instagram.com/sunrise_agriproducts?utm_source=qr&igsh=MWUzN3EydXN4aWhvYw==" className="text-light me-3">
              <img src={insta} alt="instagram" className="" /></a></li>
              
        </ul>
      </div>
      </Col>
      <Col lg={6} sm={12} className=''>
          <form onSubmit={handleSubmit} className='d-flex flex-column infor-di mi-gray'>
          <h4 className="subtitle">"We’d love to hear from you!
      </h4>
              <h2 className="title">WRITE A MESSAGE
              </h2>
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile *</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                className="form-control"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject *</label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message *</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="theme-btn mx-auto">
              Submit
            </button>
          </form>
          {formStatus && <p className="form-status">{formStatus}</p>}
        </Col>
      </Row>
      <Row>
        <div className='map my-5'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d59998.94492889209!2d73.79721836793637!3d19.96927524533257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m5!1s0x3bddeaa8f18ca4c5%3A0xec43c2e6741af020!2sSamruddhi%20Park%20Apartment!3m2!1d19.9692798!2d73.8384097!4m0!5e0!3m2!1sen!2sin!4v1736426060566!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </Row>
    </Container>
  );
};

export default ContactForm;