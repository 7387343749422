import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import icon from '../assets/img/check.svg';
import icon1 from '../assets/img/icon-1.svg';
import icon2 from '../assets/img/icon-2.svg';
import icon3 from '../assets/img/icon-3.png';
import icon4 from '../assets/img/icon-4.png';

import TestimonialCarousel from '../components/TestimonialCarousel';

import ButtonLink from '../components/ButtonLink';

function About() {
    return (
        <div>
          <section className="welcome-message section-padding">
        <Container>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={6} sm={12}>
            <div className="welcome-img">
              <img src={require('../assets/img/welcome-ing.jpg')} alt="product" className='img-fluid rounded' />
              </div>
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={6} sm={12}>
            <div className="d-flex flex-column infor-di">
              
              <h2 className="title">Sunrise Agri Products</h2>
              <p>From farm to final product, we specialize in premium dehydrated fruits and vegetables that preserve nature's goodness while meeting today's global food demands. Our innovative processing techniques transform fresh produce into convenient granules, powders, and flakes – maintaining optimal nutrition and authentic taste for customers worldwide.</p>
              <h4 className="subtitle mt-4">Our Vision</h4>
              <p>To be a global leader in the dehydrated food industry by delivering products that inspire trust, promote health, and contribute to a sustainable future. We envision a world where quality, innovation, and environmental stewardship go hand in hand to meet the growing demands of a dynamic food industry.</p>
              <h4 className="subtitle mt-4">Our Mission</h4>
              <p>Our mission is to enrich lives by offering premium dehydrated fruits and vegetables that exceed customer expectations in quality, taste, and sustainability At Sunrise Agri Products, we aim to redefine what it means to deliver quality and sustainability, one product at a time.</p>
              
              
              
             
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
      <section className="commitment-tab section-padding">
              <Container>
              <Row>
                  <Col xxl={8} xl={10} lg={10} sm={12} className="mx-auto text-center">
                    <h2 className="title">Our Journey</h2>
                    <p className="mt-4">
                    What began in 2018 as a vision to revolutionize food accessibility has blossomed into a global enterprise. Founded on the rich agricultural heritage of India, Sunrise Agri Products bridges traditional farming wisdom with modern food innovation.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <ul className="journey-list">
                    <li>
                      <div className="journey-div">
                        Establishment with initial focus on local markets
                      </div>
                      <span>2018</span>
                    </li>
                    <li>
                      <span>2019</span>
                      <div className="journey-div">
                      Implementation of advanced dehydration technology
                      </div>
                    </li>
                    <li>
                      <div className="journey-div">
                      First international export partnerships
                      </div>
                      <span>2020</span>
                    </li>
                    <li>
                      <span>2021</span>
                      <div className="journey-div">
                      Expansion of farmer network and processing facilities
                      </div>
                    </li>
                    <li>
                      <div className="journey-div">
                      Continuous growth in global market presence
                      </div>
                      <span>2022</span>
                    </li>
                  </ul>
                </Row>
              </Container>
       </section>

        <section className="welcome-message section-padding">
        <Container>
        <Row>
                  <Col xxl={6} xl={8} lg={10} sm={12} className="mx-auto text-center">
                    <h2 className="title">Core Commitments</h2>
                  </Col>
                </Row>
                <Row className="mt-5">
                <Col md={3} sm={6} className="">
                <div>
                  
                  <div className="d-flex flex-column infor-di mi-gray commitment-box">
                  <span className="commit-img"><img src={icon1} alt="icon" className="" /></span>
                    <h3 className="commit-title">Agricultural Excellence</h3>
                    
                    <ul className="listname commitmetn-list">
                      <li><span></span>Direct partnerships with experienced farmers</li>
                      <li><span></span>Careful selection of premium produce</li>
                      <li><span></span>Sustainable farming practices support</li>
                    </ul>
                  </div>
                </div>
                </Col>

                <Col md={3} sm={6} className="">
                <div>
                  
                  <div className="d-flex flex-column infor-di mi-gray commitment-box">
                  <span className="commit-img"><img src={icon2} alt="icon" className="" /></span>
                    <h3 className="commit-title">Quality Assurance</h3>
                    
                    <ul className="listname commitmetn-list">
                      <li><span></span>State-of-the-art dehydration technology</li>
                      <li><span></span>Rigorous quality control processes</li>
                      <li><span></span>International food safety certifications</li>
                    </ul>
                  </div>
                </div>
                </Col>

                <Col md={3} sm={6} className="">
                <div>
                  
                  <div className="d-flex flex-column infor-di mi-gray commitment-box">
                  <span className="commit-img"><img src={icon3} alt="icon" className="" /></span>
                    <h3 className="commit-title">Environmental Responsibility</h3>
                    
                    <ul className="listname commitmetn-list">
                      <li><span></span>Water-efficient processing methods</li>
                      <li><span></span>Minimal waste production</li>
                      <li><span></span>Eco-friendly packaging solutions</li>
                    </ul>
                  </div>
                </div>
                </Col>

                <Col md={3} sm={6} className="">
                <div>
                  
                  <div className="d-flex flex-column infor-di mi-gray commitment-box">
                  <span className="commit-img"><img src={icon4} alt="icon" className="" /></span>
                    <h3 className="commit-title">Customer Success</h3>
                    
                    <ul className="listname commitmetn-list">
                      <li><span></span>Consistent product quality</li>
                      <li><span></span>Reliable delivery systems</li>
                      <li><span></span>Responsive customer support</li>
                    </ul>
                  </div>
                </div>
                </Col>
                </Row>
        </Container>
      </section>

      
       <section className="blog-wrapper section-padding">
        <Container>
            <Row>
                <Col lg={6} sm={12}>
                <div className="d-flex flex-column infor-di">
                  <h4 className="subtitle">Client Testimonials
                  </h4>
                  <h2 className="title">Trusted by Many,
                  Loved by All</h2>
                  <p>
                  Here's what our clients are saying about us! Their words inspire us to do even better every day.
                  </p>
                </div>
                </Col>
                <Col lg={6} sm={12}>
                <TestimonialCarousel />
                </Col>
                <Col md={2} sm={12}>
                <ButtonLink link="https://www.google.com/search?q=sunrise+agri+products+nashik+reviews&oq=sunris&gs_lcrp=EgZjaHJvbWUqDggCEEUYJxg7GIAEGIoFMgYIABBFGDwyBggBEEUYOTIOCAIQRRgnGDsYgAQYigUyCggDEAAYkgMYgAQyEAgEEAAYgwEYsQMYgAQYigUyEAgFEC4YgwEYsQMYgAQYigUyBggGEEUYPTIGCAcQRRg90gEINTQwN2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bddeb0e1f7ab0d3:0x632eaf9d177bfd17,1,,,," name="Google Reviews" />
                </Col>
            </Row>
        </Container>
     </section>
    </div>
    );
}

export default About;
