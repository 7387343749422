import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import WhatsAppButton from "./WhatsAppButton";
import '../assets/css/Footer.css'; // Import custom styles if needed
import logo from '../assets/img/logo.png';
import locat from '../assets/img/geo-alt.svg';
import call from '../assets/img/telephone.svg';
import email from '../assets/img/envelope.svg';
import fb from '../assets/img/facebook.svg';
import insta from '../assets/img/instagram.svg';

const Footer = () => {
  const location = useLocation();
  const [year, setYear] = useState(new Date().getFullYear());


  // Check if the current route is not the home page
  const isInnerPage = location.pathname !== '/';
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);
  return (
    <footer className={isInnerPage ? 'footer-inner' : 'footer-default'}>
      <Container>
        <Row>
          {/* Column 1 */}
          <Col md={4} sm={6} className="mb-4">
            <div className='widget'>
            <img src={logo} alt="sunrise logo" className="logo" />
            <p>
              We are Renowned Suppliers & Exporters of Agri Products. 
              Contact us for more information.
            </p>
            <ul className="social-icon d-flex">
              <li><a href="#facebook" className="text-light me-3">
              <img src={fb} alt="facebook" className="" /></a></li>
              <li><a href="https://www.instagram.com/sunrise_agriproducts?utm_source=qr&igsh=MWUzN3EydXN4aWhvYw==" className="text-light me-3">
              <img src={insta} alt="instagram" className="" /></a></li>
              
            </ul>
            </div>
          </Col>

          {/* Column 2 */}
          <Col md={2} sm={6} className="mb-4">
          <div className='widget'>
            <h5>Quick links</h5>
            <ul className="list-unstyled">
              <li><a href="/about">About us</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/gallery">Gallery</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
            </div>
          </Col>

          {/* Column 3 */}
          <Col md={3} sm={6} className="mb-4">
          <div className='widget widget-product'>
            <h5>Product list</h5>
            <ul className="list-unstyled">
              
            <li><a href="/product">Product</a></li>
            <li><a href="/deveg">Dehydrated vegetables</a></li>
            <li><a href="/drifruit">Fruit powder</a></li>
              
            </ul>
            </div>
          </Col>

          {/* Column 4 */}
          <Col md={3} sm={6} className="mb-4">
          <div className='widget'>
          <h5>Contact Us</h5>
            <ul className="contact-info">
              <li><span>
              <img src={locat} alt="address" className="" />
              </span>Sai Samruddhi Appartment, Jail Road, Nashik 422101, MH - India. </li>
              <li><span>
              <img src={call} alt="call" className="" /></span>
              <a href='tel:9359731355'> +91 9359731355</a>/<a href='tel:7798882333'>+91 77988 82333</a></li>
              <li><span>
              <img src={email} alt="email" className="" /></span><a href='tel:info@sunriseagriproducts.com'> info@sunriseagriproducts.com </a></li>
            </ul>
            </div>
          </Col>
        </Row>
        
      </Container>
      <div className="copyright-bar">
      <Container>
      <Row>
          <Col className='d-flex align-item-center justify-content-between'>
            <p className="text-center mb-0">{year}, All Rights Reserved</p>
            <p className="text-center mb-0">© Copyright reserved by <a href="https://webique.in/">Webique Technology</a></p>
          </Col>
        </Row>
      </Container>
      </div>
      <WhatsAppButton />
    </footer>
    
  );
};

export default Footer;
