import React from "react";
import { Container, Row, Col, Tabs, Tab, } from "react-bootstrap";
import icon from '../assets/img/check.svg';

function Product() {
    return (
        <section className="welcome-message section-padding">
        <Container>
            <Row className="">
            <Col sm={12}>
            <div className="d-flex flex-column infor-di mb-5">
              <h2 className="title">Welcome to Sunrise Agri Product
              </h2>
            </div>
            </Col>
            </Row>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col md={4} sm={12}>
              <img src={require('../assets/img/tab-section.jpg')} alt="product" className='img-fluid rounded' />
            </Col>
  
            {/* Column 2: Information */}
            <Col md={8} sm={12}>
            <div className="d-flex flex-column infor-di">
              
              <p> Your Trusted Supplier and Exporter of Premium Dehydrated Vegetables   </p>
              <p>At Sunrise Agri Products, we take pride in being one of the leading suppliers and exporters of
superior-quality dehydrated vegetables. Our products are processed with utmost care to ensure they
are completely free from adulteration and meet the highest industry standards</p>
              
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col sm={12}>
            <div className="d-flex flex-column infor-di mt-5">
              <h2 className="title">Our Product Range 
              </h2>
            </div>
            </Col>
            </Row>
          <Row>
            <Col md={12} sm={12} className="tab-information product-type py-5">
            <div className="tab-section">
        <Tabs defaultActiveKey="dehydrated" id="product-tabs" className="mb-3 ">
          <Tab eventKey="dehydrated" title="Dehydrated vegetables" className="mb-3 ">
          
            <Row className='justify-content-center'>
                
            <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray mt-0">
              <h2 className="title">Quality Assurance </h2>
              <p> We are committed to delivering only the best to our customers. Here’s how we ensure top-notch
quality: </p>
                <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Rigorous Quality Checks :</strong> Multiple checks at every stage of processing. </p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Hygienic Processing Environment :</strong> Adherence to international standards for hygiene, packing, and
                processing. </p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Optimal Moisture Control :</strong> Ensuring the perfect texture and preservation of nutrients in our
                products. </p></li>
                
              </ul>
              </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray mt-0">
              <h2 className="title">Why Choose Sunrise Agri Products? </h2>
              <p>
              Our dehydrated vegetables are ideal for use as: 
              </p>
              <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Additives</strong> </p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Supplements</strong></p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Ingredients in culinary and medicinal applications </strong></p></li>
                
             </ul>
              <p>
              By combining advanced processing techniques with a strict focus on hygiene and quality, we
guarantee products that exceed customer expectations. 
              </p>
              <p>
              By combining advanced processing techniques with a strict focus on hygiene and quality, we
guarantee products that exceed customer expectations. 
              </p>
              </div>
            </Col>
            
            
            
            
            </Row>
          </Tab>
          <Tab eventKey="feature2" title="Spray Dried Fruit powder" className="mb-3 ">
          
            <Row className='justify-content-center'>
            
            <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray mt-0">
              <h2 className="title">Applications of Spray-Dried Products</h2>
              <p> Spray-dried fruit powders are versatile and safe for use in a variety of applications, such as:
              </p>
                <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Flavor and Color Enhancements</strong>  For taste, flavor, and visual appeal</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Ingredients in Food and Beverages</strong>Used in fruit juices, sauces, and pickles.</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Ready-to-Eat and Seasoning</strong> Perfect for ready-to-eat meals and spice blends.</p></li>
                <li>
                <img src={icon} alt="icon" className="" /><p>Ingredients for taste, flavor, and color enhancement</p></li>
                <li><img src={icon} alt="icon" className="" /><p>Additives for fruit juices, sauces, and pickles</p></li>
                <li><img src={icon} alt="icon" className="" /><p>Components in ready-to-eat meals and seasonings</p></li>
                <li><img src={icon} alt="icon" className="" /><p>Functional ingredients in spice blends</p></li>
                
              </ul>
              </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray mt-0">
              <h2 className="title">Why Choose Spray Drying? </h2>
              <p>
              Spray drying is an advanced technique widely used to produce fruit powders that offer:

              </p>
              <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>High Quality</strong> Retains natural taste, flavor, and color</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Low Water Activity:</strong>Ensures longer shelf life and better preservation.</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Convenience </strong>Makes transportation, storage, and handling easier.</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Natural Taste</strong>Maintains the natural essence and reaction of the fruit.</p></li>
                
             </ul>
              <p>
              The physicochemical properties of spray-dried powders depend on controlled processing
variables, ensuring consistent and superior quality.
              </p>
              </div>
            </Col>
            
            
            
            
            
            </Row>
          </Tab>
        </Tabs>
    </div>
            </Col>
          </Row>
        </Container>
        <section className="product-tab section-padding pb-0">
      <Container>
        <Row>
          <Col md={12} sm={12} className="mx-auto">
            <h3>Choose Sunrise Agri Products for high-quality dehydrated vegetables that deliver taste, convenience,
            and reliability. Let us partner in meeting your culinary and industrial needs! </h3>
          </Col>
          
        </Row>
      </Container>
    </section>
        </section>
    );
}

export default Product;
